import Link from "next/link";
import { Footer } from "../components/molecules/Footer";

export default function NotFound() {
  return (
    <div className="flex flex-col justify-between h-screen">
      <div className="flex flex-1 items-center justify-center">
        <div className="flex flex-col items-center pr-6">
          <h1 className="font-semibold text-lg">404</h1>
        </div>
        <div className="flex flex-col pl-5 border-l border-neutral">
          <h5 className="mb-2 font-normal uppercase">Page not found</h5>
          <p className="mb-2">Sorry, the page you are looking for can&apos;t be found.</p>
          <Link href="/" className="px-3 py-2 text-sm uppercase btn">
            back to Homepage
          </Link>
        </div>
      </div>
      <Footer className="mt-6 mb-6" />
    </div>
  );
}
